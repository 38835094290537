'use client';

import FocusTrap from 'focus-trap-react';
import { useState, useEffect, useCallback } from 'react';
import { useKey } from 'react-use';

import { ChildrenProps } from '@/types/common.types';

import {
  DropdownContextType,
  DropdownProvider,
} from '@/components/Dropdown/contexts/useDropdownContext';

import styles from '@/components/Dropdown/Dropdown.module.scss';
import { DropdownLabel } from '@/components/Dropdown/DropdownLabel';
import { DropdownMenu } from '@/components/Dropdown/DropdownMenu';
import { DropdownOption } from '@/components/Dropdown/DropdownOption';
import { Focusable } from '@/components/Focusable';

// =================================================================

interface DropdownProps
  extends Omit<DropdownContextType, 'isDropdownVisible' | 'toggleDropdown'>,
    ChildrenProps {
  shouldHidden?: boolean;
  onMenuOpen?: () => void;
  open?: boolean;
}

// =================================================================

export const Dropdown = (props: DropdownProps) => {
  const { children, shouldHidden, onMenuOpen, open = false, ...rest } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState(open);

  const toggleDropdown = useCallback(() => {
    setIsDropdownVisible(!isDropdownVisible);
    if (typeof onMenuOpen === 'function') {
      onMenuOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownVisible]);

  useEffect(() => {
    if (shouldHidden) {
      setIsDropdownVisible(false);
    }
  }, [shouldHidden]);

  useKey('Escape', () => setIsDropdownVisible(false));

  return (
    <DropdownProvider
      isDropdownVisible={isDropdownVisible}
      toggleDropdown={toggleDropdown}
      {...rest}
    >
      <FocusTrap
        active={isDropdownVisible}
        focusTrapOptions={{ allowOutsideClick: true, escapeDeactivates: false }}
      >
        <Focusable shouldFocusOnMount={false} className={styles.dropdown}>
          {children}
        </Focusable>
      </FocusTrap>
    </DropdownProvider>
  );
};

Dropdown.Label = DropdownLabel;
Dropdown.Menu = DropdownMenu;
Dropdown.Option = DropdownOption;
