import React, { useRef, useEffect } from 'react';

/**
 * @url https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#usecapture
 */

// =================================================================

type RefObject<T> = React.RefObject<T>;

// =================================================================

export const useClickOutside = <T extends HTMLElement>(
  handler: () => void,
  useCapture: boolean = true,
): RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        handler();
      }
    };

    document.addEventListener('click', handleClickOutside, useCapture);
    return () => document.removeEventListener('click', handleClickOutside, useCapture);
  }, [handler, useCapture]);

  return ref;
};
