'use client';

import React, { forwardRef } from 'react';

import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';

import { usePathname, Link } from '@/navigation';

// =================================================================

type NavLinkProps = {
  href: string;
  className?: string;
  activeClassName?: string;
  onClick?: () => void;
  children: React.ReactNode;
  exact?: boolean; // must be exactly the same
  checkFirstPart?: boolean; // it useful for subdomens
  isScrolable?: boolean;
  title?: string;
};

// =================================================================

function getFirstPart(url: string) {
  const cleanUrl = url.split('?')[0];
  const parts = cleanUrl.split('/');
  return `/${parts[1]}` || '';
}

// =================================================================

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const {
    href,
    className,
    activeClassName,
    onClick,
    children,
    title,
    exact = false,
    isScrolable = true,
    checkFirstPart = false,
    ...rest
  } = props;

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const currentUrl = `${pathname}?${searchParams}`;

  const isActive = exact
    ? pathname === href.replace(/\/$/, '') || pathname === href
    : checkFirstPart
      ? pathname.startsWith(getFirstPart(href))
      : currentUrl && currentUrl.startsWith(href);

  return (
    <Link
      title={title}
      href={href}
      prefetch={false}
      ref={ref}
      className={clsx(className, isActive && activeClassName)}
      onClick={onClick}
      scroll={isScrolable}
      {...rest}
    >
      {children}
    </Link>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  NavLink.displayName = 'NavLink';
}
