// =================================================================
import { useEffect, JSX, ReactNode } from 'react';

import clsx from 'clsx';

import ChevronDownSVG from '@/icons/ChevronDownSVG';
import { ChildrenProps, ClassNameProps, SVGProps } from '@/types/common.types';

import { useDropdownContext } from '@/components/Dropdown/contexts/useDropdownContext';
import { useHover } from '@/hooks/useHover';

import styles from '@/components/Dropdown/DropdownLabel.module.scss';

// =================================================================

interface DropdownLabelProps extends Partial<ChildrenProps>, ClassNameProps {
  icon?: ReactNode;
  onClick?: () => void;
  iconWidth?: number;
  iconHeight?: number;
  title?: string;
  disabled?: boolean;
  hasArrowIcon?: boolean;
  arrowIcon?: (isDropdownVisible: boolean) => ReactNode;
  activeClassName?: string;
}

// =================================================================

const defaultArrowIcon = (isDropdownVisible: boolean) => (
  <ChevronDownSVG
    width={16}
    height={16}
    className={clsx(styles.chevronDownIcon, {
      [styles.active]: isDropdownVisible,
    })}
  />
);

// =================================================================

export const DropdownLabel = (props: DropdownLabelProps) => {
  const {
    icon,
    arrowIcon = defaultArrowIcon,
    children,
    className,
    onClick,
    title,
    disabled = false,
    hasArrowIcon = true,
    activeClassName,
  } = props;

  const { toggleDropdown, trigger, isDropdownVisible } = useDropdownContext();

  const { ref, isHovered } = useHover<HTMLButtonElement>();

  useEffect(() => {
    if (disabled) return;
    else if (trigger === 'hover' && isHovered) {
      toggleDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, trigger, disabled]);

  return (
    <button
      ref={ref}
      aria-label="Toggle dropdown"
      title={title}
      aria-haspopup="true"
      aria-expanded={isDropdownVisible}
      type="button"
      onClick={event => {
        event.stopPropagation();
        if (disabled || trigger === 'click') {
          toggleDropdown();
        } else {
          onClick?.();
        }
      }}
      onDoubleClick={event => {
        event.stopPropagation();
      }}
      className={clsx(styles.dropdownLabel, className, isDropdownVisible && activeClassName)}
      disabled={disabled}
    >
      {icon}
      {children}
      {hasArrowIcon && arrowIcon(isDropdownVisible)}
    </button>
  );
};
