import React, { Fragment } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';

import ArrowSVG from '@/icons/ArrowSVG';

import { Dropdown } from '@/components/Dropdown/Dropdown';
import { FormCheckbox } from '@/components/Form/FormCheckbox';
import styles from '@/components/Form/Select/SelectDropdown.module.scss';
import {
  MultiValue,
  MultiValueContainer,
  SingleValue,
} from '@/components/Form/Select/SelectValues';
import { useSelectContext } from '@/components/Form/Select/context/SelectProvider';

// =================================================================

export const SelectDropdown = () => {
  const {
    isMulti,
    selectedOptions,
    selectedOption,
    handleChange,
    onMenuOpen,
    menuIsOpen,
    name,
    isDisabled,
    placeholder,
    options,
    isActiveDropdownItem,
    isMaxSelectableLimit,
    labelType = 'primary',
    position,
    trigger = 'click',
    onSubmitted,
    selectMenuClassName,
  } = useSelectContext();

  return (
    <div className={styles.selectDropdownWrapper}>
      <Dropdown
        trigger={trigger}
        value={
          isMulti
            ? (selectedOptions?.map(selected => selected.value) as number[] | string[])
            : selectedOption?.value
        }
        onChange={value => {
          handleChange(value);
          if (typeof onSubmitted === 'function') {
            onSubmitted();
          }
        }}
        onMenuOpen={onMenuOpen}
        position={position}
      >
        <Dropdown.Label
          title={name}
          className={clsx(styles.selectLabel, styles[labelType])}
          activeClassName={styles.activeDropdownLabel}
          arrowIcon={isDropdownVisible => (
            <ArrowSVG
              className={clsx(styles.arrowClassName, styles[labelType], {
                [styles.active]: isDropdownVisible,
              })}
            />
          )}
          disabled={isDisabled}
        >
          {labelType === 'primary' ? (
            <div className={styles.selectLabelContent}>
              <SingleValue>{placeholder}</SingleValue>
              {isMulti ? (
                <MultiValueContainer>
                  <MultiValue>{selectedOptions.map(item => item.label).join(', ')}</MultiValue>
                </MultiValueContainer>
              ) : (
                <MultiValue>{selectedOption?.label}</MultiValue>
              )}
            </div>
          ) : (
            <Fragment>
              {isMulti ? (
                <MultiValueContainer>
                  {selectedOptions.map(selected => (
                    <MultiValue key={selected.value}>{selected.label}</MultiValue>
                  ))}
                </MultiValueContainer>
              ) : (
                <SingleValue>{selectedOption?.label}</SingleValue>
              )}

              {isEmpty(selectedOptions) && isEmpty(selectedOption) && (
                <SingleValue>{placeholder}</SingleValue>
              )}
            </Fragment>
          )}
        </Dropdown.Label>

        {menuIsOpen && (
          <Dropdown.Menu dropdownClassName={clsx(styles.dropdownMenu, selectMenuClassName)}>
            {options.map((option, index) => (
              <li key={option.value}>
                <Dropdown.Option
                  as="button"
                  type="button"
                  value={option.value}
                  activeClassName={styles.activeDropdownItem}
                  isLast={index === options.length - 1}
                >
                  <FormCheckbox
                    onChange={() => handleChange(option.value)}
                    label={option.label}
                    name={''}
                    shape="round"
                    className={styles.dropdownItem}
                    checked={isActiveDropdownItem(option.value)}
                    disabled={!isActiveDropdownItem(option.value) && isMaxSelectableLimit}
                  />
                </Dropdown.Option>
              </li>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};
