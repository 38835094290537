'use client';

import clsx from 'clsx';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { useDropdownContext } from '@/components/Dropdown/contexts/useDropdownContext';
import { useClickOutside } from '@/hooks/useClickOutside';

import { FadeTransition } from '@/components/Animations';
import styles from '@/components/Dropdown/DropdownMenu.module.scss';
import { List } from '@/components/List';

// =================================================================

type DropdownMenuProps = ChildrenProps &
  ClassNameProps & {
    dropdownClassName?: string;
  };

// =================================================================

export const DropdownMenu = (props: DropdownMenuProps) => {
  const { children, className, dropdownClassName } = props;

  const { toggleDropdown, isDropdownVisible, position = 'bottom-left' } = useDropdownContext();

  const dropdownRef = useClickOutside<HTMLDivElement>(toggleDropdown, false);

  return (
    <FadeTransition inProp={isDropdownVisible}>
      <div
        onClick={event => event.stopPropagation()}
        onDoubleClick={event => event.stopPropagation()}
        ref={dropdownRef}
        aria-label="Dropdown menu"
        className={clsx(styles.dropdown, styles[position], dropdownClassName)}
      >
        <List
          role="menu"
          aria-orientation="vertical"
          className={clsx(styles.dropdownList, className)}
        >
          {children}
        </List>
      </div>
    </FadeTransition>
  );
};
